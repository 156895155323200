import React from 'react';
import styled, {withTheme} from 'styled-components';
import useMedia from 'react-use/lib/useMedia';

// Components
import {Button, Space, Line, Visible, Nav} from "../components";
import withSecondaryTheme from "../components/withSecondaryTheme";
import Scrollspy from 'react-scrollspy'

// Styles
import {Flex} from "../styles/index";

// Images
import gear from '../images/settings.png';
// import fb from '../images/social/fb.png';
// import linkedin from '../images/social/in.png';
// import twitter from '../images/social/twitter.png';

const Navigation = (props) => {
    const isTablet = useMedia(`(max-width: ${props.theme.sizes.laptop}px)`);
    const {onChoose} = props;

    const menuContent = [
        ['#agePass', 'AgePass'],
        ['#onlineAgeVerification', 'Online Age Verification'],
        ['#howItWorks', 'How it works'],
        ['#TAVC', 'The Age Verification Card'],
        ['/faqs', 'FAQs'],
        ['#contact', 'Contact']
    ]

    return (
        <List>
            <ScrollspyStyled 
                items={ ['agePass', 'onlineAgeVerification', 'howItWorks', 'TAVC', '', 'contact'] }
                currentClassName='current'
                rootEl='body'
                componentTag='div'
                offset={-80}
            >   
                {menuContent.map(([link, text]) => (
                    <Nav onClick={onChoose} key={text} to={link}>{text}</Nav>
                ))}
            </ScrollspyStyled>
            <Button
                height="47"
                width="198px"
                link={'https://agewallet.agepass.net/?external-signup=true'}
                target="_self"
            >Get an AgePass</Button>
            <Space height="18"/>
            <ExternalLink href="https://agewallet.agepass.net/settings">
                        <span>
                            <Icon src={gear} alt="gear"/>
                        </span>
                Manage your AgePass
            </ExternalLink>
            <Line width="178px" height="2px"/>
            <Wrapper>
                <Flex justifyContent={isTablet ? 'flex-end' : 'unset'}>
                    {/* <SocialList>
                        <a href="https://www.linkedin.com/company/avsecure-llc/" target="_blank">
                            <Social src={linkedin} alt="linkedin"/>
                        </a>
                        <a href="https://www.facebook.com/avsecureonline/" target="_blank">
                            <Social src={fb} alt="facebook"/>
                        </a>
                        <a href="https://twitter.com/avsecurellc?lang=en" target="_blank">
                            <Social src={twitter} alt="twitter"/>
                        </a>
                    </SocialList> */}
                    <Visible value={!isTablet}>
                        <ListLink>
                            <ExternalLink href="https://www.avsecure.com" target="_blank">Visit avsecure.com</ExternalLink>
                            <Nav to="/privacy-policy">Privacy Policy</Nav>
                            <Nav to="/terms-conditions">Terms &amp; Conditions</Nav>
                        </ListLink>
                    </Visible>
                </Flex>
            </Wrapper>
        </List>
    )
};

export default withSecondaryTheme(withTheme(Navigation));

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const ExternalLink = styled.a`
    text-decoration: none;
    opacity: 0.3;
    font-family: ${({theme}) => theme.fontFamily};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    text-align: right;
    color: #ffffff;
    margin: 14px 0;
    display: flex;
    transition: opacity 0.2s;
    &:hover{
        opacity: 1;
    }
`;

const Icon = styled.img`
    width: 22px;
    height: 22px;
    margin: 0 18px;
`;

const Wrapper = styled.div`
    width: 100%;
    
`;

const Social = styled.img`
    margin: 12px 0;
    height: 18px;
`;

const SocialList = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: ${props => props.theme.sizes.phone}px) {
        flex-direction: row;
        margin: 30px 0px 0 0;
        ${Social}{
            margin: 0 0 0 45px;
        }
    }
`;

const ListLink = styled.div`
    width: 100%;
    a {
        opacity: 0.3;
        font-family: ${({theme}) => theme.fontFamily};
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: semi-condensed;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
        text-decoration: none;
        display: block;
        margin: 13px 0 0px 0;
    }
`;

const ScrollspyStyled = styled(Scrollspy)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    & .current {
        opacity: 1;
    }
`;