import React from 'react';

const withCarouselState = ComposedComponent =>
    class extends React.Component {
        state = {
            current: 0,
            number: 0,
        };

        setCurrent = value => {
            this.setState({
                current: value,
            });
        };

        setNumber = value => {
            this.setState({
                number: value,
            });
        };

        render() {
            const carousel = {
                state: this.state,
                actions: {
                    setCurrent: this.setCurrent,
                    setNumber: this.setNumber,
                },
            };

            return <ComposedComponent {...this.props} carousel={carousel} />;
        }
    };

export default withCarouselState;
