import React from 'react';
import { ThemeProvider } from 'styled-components';

import { secondaryTheme } from '../theme/index';

const withSecondaryTheme = ComposedComponent =>
    class extends React.Component {
        render() {
            return (
                <ThemeProvider theme={secondaryTheme}>
                    <ComposedComponent {...this.props} />
                </ThemeProvider>
            );
        }
    };

export default withSecondaryTheme;
