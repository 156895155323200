import React from 'react';
import styled from 'styled-components';

// Components
import { Logo, Sidebar } from "./index";

// Images
import fb from '../images/social/fb.png';
import linkedin from '../images/social/in.png';
import twitter from '../images/social/twitter.png';

// const SOCIAL_LIST = [
//     [linkedin, 'https://www.linkedin.com/company/avsecure-llc/'],
//     [fb, 'https://www.facebook.com/avsecureonline/'],
//     [twitter, 'https://twitter.com/avsecurellc?lang=en'],
// ];

const EmptySidebar = ({ children }) => {
    return (
        <Sidebar>
            <LogoWrapper>
                <Logo/>
            </LogoWrapper>
            {/* <SocialList>
                {SOCIAL_LIST.map(([img, url]) => {
                    return (
                        <a key={url} href={url} target="_blank">
                            <Social src={img} alt={url}/>
                        </a>
                    )
                })}

            </SocialList> */}
            { children }
        </Sidebar>
    )
};

export default EmptySidebar;

const LogoWrapper = styled.div`
    position: absolute;
    z-index: 1000;
`;

const Social = styled.img`
    margin: 12px 0;
    height: 18px;
`;

const SocialList = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10px;

    @media (max-width: ${props => props.theme.sizes.phone}px) {
        flex-direction: row;
        margin: 30px 0px 0 0;
        ${Social} {
            margin: 0 0 0 45px;
        }
    }
`;