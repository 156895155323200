import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {mobile} from "../utils";

// Styles
import {Link as LocalLink} from "../styles";

const Button = ({children, height = 35, width = 291, link = '/', target}) => {
    const Link = target ? ExternalLink : LocalLink;
    return (
        <Container height={height} width={width}>
            <Link to={link} href={link} target={target}  aria-label={link} >
                <Wrapper>
                    <Value>
                        {children}
                    </Value>
                </Wrapper>
            </Link>
        </Container>

    )
};

Button.propTypes = {
    link: PropTypes.string,
    target: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    children: PropTypes.node,
};

export default Button;

const Wrapper = styled.div`
    color: ${({ theme }) => theme.primaryColor};
    box-sizing: border-box;
    border: solid 2px ${({ theme }) => theme.primaryColor};
    cursor: pointer;
    transition: background,color 0.2s;
    &:hover, div:hover{
       background: ${({ theme }) => theme.hover};
       color: ${({ theme }) => theme.color};
    }
`;

const Container = styled.div`
    height: ${props => props.height}px;
    width: ${props => props.width};
    ${Wrapper}{
        height: ${props => props.height}px;
        width: ${props => props.width};
    }
`;

const Value = styled.div`
    font-family: ${({ theme }) => theme.fontFamily};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.06;
    text-align: center;
    color: ${({ theme }) => theme.primaryColor};
    transition: color 0.2s;
    &:hover {
        color: ${({ theme }) => theme.color};
    }
    
    @media (max-width: ${mobile}px) {
        font-size: 13px;
    }
`;

const ExternalLink = styled.a`

`;
