import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import posed, {PoseGroup} from 'react-pose';

// Images
import close from '../images/cross.png'

const Lightbox = ({src, show, onClose, poster}) => {
    const [element, setElement] = useState(null);
    const [doc, setDoc] = useState(null);

    let videoEl = useRef();

    useEffect(() => setElement(document.createElement('div')), []);

    useEffect(() => setDoc(document), []);

    useEffect(() => {
        if (element && doc) {
            doc.body.appendChild(element);

            return () => {
                doc.body.removeChild(element)
            }
        }

    }, [element, doc]);

    useEffect(() => {
        if (videoEl instanceof Element) {
            videoEl.setAttribute('disablePictureInPicture', '')
        }
    });

    if (!element) {
        return null;
    }

    return ReactDOM.createPortal((
            <PoseGroup>
                {show && (
                    <Wrapper key="wrapper">
                        <Backdrop key="backdrop" onClick={onClose}/>
                        <Content key="video">
                            <EnterButton key="close">
                                <Close src={close} onClick={onClose}/>
                            </EnterButton>
                            <Video
                                ref={(el) => videoEl = el}
                                src={src}
                                poster={poster}
                                controls
                                controlsList="nodownload"
                            />
                        </Content>
                    </Wrapper>
                )}
            </PoseGroup>
        ),
        element,
    );
};

export default Lightbox;

const Shade = posed.div({
    enter: {
        opacity: 1,
        transition: {
            duration: 200
        }
    },
    exit: {opacity: 0}
});

const EnterButton = posed.div({
    enter: {
        opacity: 1,
        delay: 300,
        position: 'relative',

    },
    exit: {opacity: 0}
});

const EnterVideo = posed.video({
    enter: {
        opacity: 1,
        delay: 300,
        position: 'relative'
    },
    exit: {opacity: 0}
});

const Wrapper = styled(Shade)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
`;

const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 101;
    position: relative;
`;

const Video = styled(EnterVideo)`
    position: relative;
    width: auto;
    height: 50vh;
    background: #ececec;
    max-width: 100vw;
`;

const Close = styled.img`
    height: 85px;
    width: 85px;
    z-index: 150;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    @media (max-width: ${props => props.theme.sizes.phone}px) {
        width: 55.8px;
        height: 55.8px;
    }
`;

const Content = styled(Shade)`
    position: absolute;
    z-index: 110;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;
