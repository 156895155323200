import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import {Link as GatsbyLink} from "gatsby";

const Nav = (props) => {
    const [location, setLocation] = useState({});

    const isActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? {style: {opacity: 1}} : null
    }

    useEffect(() => setLocation(window.location), []);
    return (
        <StyledLink {...props}
            getProps={isActive}
            active={props.init && location.pathname === '/'}
        >
            {props.children}
        </StyledLink>
    )
};

export default Nav;

const StyledLink = styled(GatsbyLink)`
    text-decoration: none;
    opacity: ${props => props.active ? 1 : 0.3};
    font-family: ${({theme}) => theme.fontFamily};
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1.25;
    text-align: right;
    color: #ffffff;
    margin: 14px 0;
    display: flex;
    transition: opacity 0.2s;
    
    &:hover{
        opacity: 1;
    }
`;