import React from 'react';
import {withTheme} from 'styled-components';
import useMedia from 'react-use/lib/useMedia';

// Components
import {Space, Sidebar, MobileHeader, Logo} from "../components";
import Navigation from "./Navigation";


const SidebarContainer = ({ theme, children }) => {
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);

    if (isTablet) {
        return <MobileHeader/>
    }

    if (children){
        return children;
    }

    return (
        <Sidebar>
            <div>
                <Logo/>
                <Space height="63"/>
                <Navigation/>
            </div>
        </Sidebar>
    )
};

export default withTheme(SidebarContainer);
