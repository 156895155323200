import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../theme';


const Background = ({src, color, position = '0', children, padding}) => {
    if (src){
        return (
            <Image src={src} position={position} padding={padding} alt="background">
                {children}
            </Image>
        )
    }

    return (
        <Color color={color} padding={padding}>
            {children}
        </Color>
    )
};
Background.propTypes = {
    src: PropTypes.string,
    color: PropTypes.string,
    position: PropTypes.string,
    children: PropTypes.node,
};

export default Background;

const Image = styled.div`
    position: relative;
    background-image: url(${props => props.src});
    background-position: ${props => props.position};
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: ${({padding}) => padding ? padding.def : 0};

    ${media.laptop`
        padding: ${({padding}) => padding ? padding.laptop : 0};
    `}

    ${media.phone`
        padding: ${({padding}) => padding ? padding.phone : 0};
    `}
`;

const Color = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.color};

    padding: ${({padding}) => padding ? padding.def : 0};

    ${media.laptop`
        justify-content: center;
        padding: ${({padding}) => padding ? padding.laptop : 0};
    `}

    ${media.phone`
        padding: ${({padding}) => padding ? padding.phone : 0};
    `}

`;
