import primaryTheme from './primary';
import secondaryTheme from './secondary';
import { css } from 'styled-components';


const media = {
  laptop_desktop: (...args) => css` 
    @media (min-width: ${primaryTheme.sizes.laptop + 1 }px) and (max-width: ${primaryTheme.sizes.desktop}px) {
      ${css(...args)}
    }
  `,

  upto_desktop: (...args) => css`
    @media (max-width: ${primaryTheme.sizes.desktop}px) {
      ${css(...args)}
    }
  `,

  mPhone: (...args) => css`
    @media (max-width: ${primaryTheme.sizes.mPhone}px) {
      ${css(...args)}
    }
  `,
  phone: (...args) => css`
    @media (max-width: ${primaryTheme.sizes.phone}px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: ${primaryTheme.sizes.tablet}px) {
      ${css(...args)}
    }
  `,
  laptop: (...args) => css`
    @media (max-width: ${primaryTheme.sizes.laptop}px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (min-width: ${primaryTheme.sizes.desktop + 1 }px) {
      ${css(...args)}
    }
  `,
}

export { primaryTheme, secondaryTheme, media };
