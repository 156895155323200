import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Sidebar = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    )
};

Sidebar.propTypes = {
    children: PropTypes.node
};

export default Sidebar;


const Container = styled.div`
  width: 375px;
  position: fixed;
  min-height: 100vh;
  background: ${props => props.theme.primaryColor};
  padding: 20px 61px 0 27px;
`;