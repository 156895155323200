import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {createGlobalStyle} from "styled-components";
import { Helmet } from "react-helmet"

const Layout = ({children}) => (
    <React.Fragment>
        <Helmet htmlAttributes={{ lang : 'en' }}>
            <title>AgePass</title>
            <html lang="en" />
        </Helmet>
        <GlobalStyle/>
        <LayoutWrapper>
            {children}
        </LayoutWrapper>
    </React.Fragment>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;

const LayoutWrapper = styled.div`
   /* display: flex; */
`;

const GlobalStyle = createGlobalStyle`
    html {
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        font-display: swap;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    body {
        margin: 0;
        padding: 0;
        font-family: 'Encode Sans Semi Condensed';
        font-display: swap;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        overscroll-behavior: none;
        
        &.scroll-stop {
            overflow-y: hidden;
        }
    }
    
    
    @-ms-viewport {
        width: device-width;
    }
    
    html {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -ms-overflow-style: scrollbar;
    }
    
    *,
    *::before,
    *::after {
        -webkit-box-sizing: inherit;
          box-sizing: inherit;
    }
    
    a{
        text-decoration: none;
    }

    body + div {
        display: none !important;
    }
`;