import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { media } from '../theme';

const Section = styled.section`
    width: 100%;
    height: ${props => props.height};
    position: relative;
`;

const Column = styled.div`
    padding: ${props => props.padding};
    width: ${props => props.width}px;
`;

const Head = styled.h2`
    font-family: ${props => props.theme.fontFamily};
    font-size: 40px;
    font-weight: bold;
    font-stretch: semi-condensed;
    line-height: 1.25;
    text-align: left;
    margin: 6px 0 20px 0;
    color: ${({ theme }) => theme.primaryColor};

    ${media.laptop`
        font-size: 26px;
    `}

    ${media.phone`
        font-size: 26px;
    `}
`;

const SubHead = styled.div`
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 25px;
    font-stretch: semi-condensed;
    line-height: 1.6;
    text-align: left;
    font-weight: ${({ bold }) => (bold ? 800 : 400)};
    color: ${({ theme }) => theme.primaryColor};
    
    ${media.laptop`
        font-size: 18px;
    `}
    
    ${media.phone`
        font-size: 18px;
    `}
`;

const SmallHead = styled.div`
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 15px;
    font-weight: bold;
    font-stretch: semi-condensed;
    text-align: left;
    color: ${({ theme }) => theme.primaryColor};
`;

const Understroke = styled.span`
    text-decoration: underline;
`;

const Outline = styled.span`
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: semi-condensed;
    line-height: 1;
    text-align: left;
    color: ${({ theme }) => theme.primaryColor};
    display: inline-block;
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: #ffffff;
    -webkit-text-stroke-color: ${({ theme }) => theme.primaryColor};
`;

const Text = styled.div`
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 16px;
    font-stretch: semi-condensed;
    line-height: 1.38;
    text-align: left;
    font-weight: ${({ bold }) => (bold ? 800 : 400)};
    color: ${({ theme }) => theme.primaryColor};
    margin: ${({ margin = '23px 0' }) => margin};

    ${media.laptop`
        font-size: 14px;
    `}

    ${media.phone`
        font-size: 14px;
    `}
`;

const Flex = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: ${({ alignItems = 'inherit' }) => alignItems};
    justify-content: ${({ justifyContent = 'inherit' }) => justifyContent};
    flex-direction: ${({ direction = 'row' }) => direction};
`;

const Image = styled.img`
    display: inline-block;
`;

const Content = styled.div`
    margin-left: 375px;
    width: calc(100vw - 375px);

    ${media.upto_desktop`
        width: calc(100vw - 375px);
        margin-left: 375px;
    `}

    ${media.laptop`
        width: 100vw;
        margin-left: 0;
    `}
`;

const Body = styled(Content)`
    padding: 95px 265px 100px 55px;

    ${media.laptop`
        padding: 94px 32px 50px 32px;
    `}

    ${media.phone`
        padding: 94px 32px 50px 32px;
    `}
`;

const TextLegal = styled(Text)`
	color: ${({ theme }) => theme.legalColor};
	line-height: 130%;
	text-indent: ${({ textIndent = '10px' }) => textIndent};

    &:before {
	    content: ${({ clear }) => (clear ? 'none !important' : 'inherit')};
    }
`;

const HeadLegal = styled(Head)`
	color: ${({ theme }) => theme.legalColor};
`;

const SubHeadLegal = styled(SubHead)`
	color: ${({ theme }) => theme.legalColor};
	font-weight: 800;
	font-size: 24px;
    line-height: 120%;
    margin: 50px 0 30px;
`;

const ALegal = styled.a`
	font-weight: 800;
	color: ${({ theme }) => theme.legalColor};
`;

const Link = styled(GatsbyLink)``;

const OL = styled.ol`
	font-family: ${({ theme }) => theme.fontFamily};
	font-weight: ${({ bold }) => (bold ? 800 : 400)};
    font-size: ${({ fontSize = '16px' }) => fontSize};
	padding: 0;
	margin: 0;
	counter-reset: item;
	list-style-type: none;
`;

const OI = styled.li`
	font-family: ${({ theme }) => theme.fontFamily};
	color: ${({ theme }) => theme.legalColor};
    
	margin: 10px 0;
	display: table;
	counter-increment: item;

    ${media.laptop`
		font-size: 14px;
    `}

    ${media.phone`
		font-size: 14px;
    `}

	&:before {
		content: counters(item, '.') '. ';
		display: table-cell;
		padding-right: 0.6em;
	}

	${OL} > &:before {
		content: counters(item, '.') '.';
	}

	${OL} li ${OL} > li:before {
		content: counters(item, '.') ' ';
	}

	${OL} li ${OL} > li:before {
		content: '(' counter(item, lower-alpha) ')';
	}

    ${OL} li ${OL} li ${OL} > li:before {
		content: '(' counter(item, lower-roman) ')';
	}
`;

const UnorderedList = styled.ul`
	text-indent: initial;
	font-size: 16px;
	font-weight: 400;
	font-family: ${({ theme }) => theme.fontFamily};
	list-style: none;
	padding: 0 30px 0 45px;
	margin: 0;
	list-style-type: square;
`;

const UnorderedListItem = styled.li`
	font-family: ${({ theme }) => theme.fontFamily};
	counter-increment: counter;
	color: ${({ theme }) => theme.legalColor};
	margin: 10px 0;
`;

export {
    Section,
    Column,
    Head,
    Understroke,
    Outline,
    Text,
    SubHead,
    SmallHead,
    Flex,
    Image,
    Content,
    Body,
    Link,
    TextLegal,
    HeadLegal,
    SubHeadLegal,
    ALegal,
    OL,
    OI,
    UnorderedList,
    UnorderedListItem,
};
