import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../theme';


const Dots = ({active, onClick, number}) => {

    const setItem = (value) => () => onClick(value);

    const list = new Array(+number).fill(null);

    return (
        <Container>
            {list.map((item, index) => <Line onClick={setItem(index)} key={index} active={index === active}/>)}
        </Container>
    )
};

Dots.propTypes = {
    active: PropTypes.number,
    onClick: PropTypes.func,
    number: PropTypes.number,
};

Dots.defaultProps = {
    active: 0,
    onClick: () => {},
    number: 0,
};

export default Dots;

const Container = styled.div`
    display: inline-block;
`;

const Line = styled.span`
    width: 30px;
    height: 4.7px;
    opacity:${props => props.active ? 1 : 0.3} ;
    background: ${props => props.theme.primaryColor};
    margin: 2px;
    display: inline-block;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    ${media.desktop`
        width: 49px;
    `}

    ${media.desktop`
        width: 49px;
    `}

    ${media.laptop`
        width: 43px;
    `}

    ${media.phone`
        width: 25px;
    `};
`;