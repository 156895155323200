import React, {Component} from 'react';
import styled, {ThemeProvider} from 'styled-components';

import {primaryTheme} from '../theme/index';

import {Layout} from '../components';
import SidebarContainer from '../sections/SidebarContainer';

class Page extends Component {
    render() {
        return (
            <ThemeProvider theme={primaryTheme}>
                <Wrapper>
                    <Layout>
                        <SidebarContainer/>
                        {this.props.children}
                    </Layout>
                </Wrapper>
            </ThemeProvider>
        );
    }
}

export default Page;

const Wrapper = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto;
`;
