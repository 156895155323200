import React, {useState} from 'react';
import styled, {withTheme} from 'styled-components';
import posed, {PoseGroup} from 'react-pose';
import {Link as GatsbyLink} from "gatsby";

// Components
import Navigation from "../sections/Navigation";
import Logo from "./Logo";

const MobileHeader = () => {
    const [visible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!visible);
        document.body.classList.toggle('scroll-stop');
    };

    return (
        <Wrapper>
            <Header>
                <Logo height="39.1px" width="116.2px"/>
                <Burger onClick={toggle} className={visible ? 'open' : ''}>
                    <span></span>
                    <span></span>
                    <span></span>
                </Burger>
            </Header>
            <PoseGroup>
                {visible && [
                    <Backdrop key="shade" onClick={toggle}/>,
                    <NavigationWrapper key="menu">
                        <Navigation onChoose={toggle}/>
                    </NavigationWrapper>
                ]}

            </PoseGroup>
        </Wrapper>
    )
};

export default withTheme(MobileHeader);

const Menu = posed.div({
    enter: {
        opacity: 1,
        delay: 150
    },
    exit: {
        opacity: 0
    }
});

const Shade = posed.div({
    enter: {
        opacity: 0.7,
        transition: {
            duration: 180
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 200
        }
    }
});

const Link = styled(GatsbyLink)`
    display: flex;
    text-align: right;
`;

const LogoWrapper = styled.img`
  width: 160.5px;
  height: 54.1px;
`;

const Wrapper = styled.div`
    width: 100vw;
    position: fixed;
    z-index: 100;
`;

const NavigationWrapper = styled(Menu)`
    padding: 0 20px 0 0;
    background: ${props => props.theme.primaryColor};
    width: 100vw;
    height: 100vh;
    right: 0;
    position: absolute;
    z-index: 1000;
    top: 60.4px;

`;

const Header = styled.div`
    background: ${props => props.theme.primaryColor};
    width: 100%;
    height: 61.4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: absolute;
    z-index: 2000;
    ${LogoWrapper}{
        width: 116.2px;
        height: 39.1px;
    }
`;

const Backdrop = styled(Shade)`
    position: absolute;
    background: black;
    height: 100vh;
    width: 100vw;
    top: 0;
`;

const Burger = styled.div`
    width: 20px;
    height: 13px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;

    & span {
        display: block;
        height: 2px;
        background-color: #ffffff;
        width: 100%;
        position: absolute;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }


    & span:nth-child(1) {
        top: 0px;
        transform-origin: left center;
    }

    & span:nth-child(2) {
        top: 5px;
        transform-origin: left center;
    }

    & span:nth-child(3) {
        top: 10px;
        transform-origin: left center;
    }

    &.open span:nth-child(1) {
        transform: rotate(45deg);
        top: -3px;
        left: 0px;
    }

    &.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    &.open span:nth-child(3) {
        transform: rotate(-45deg);
        top: 11px;
        left: 0px;
    }
`;