import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../theme';


const Card = ({children, spacing, padding, margin = '0 0 0 -22px', height, width, basis, maxWidth, wrapWidth}) => {
    return (
        <Wrapper width={width} height={height} basis={basis} maxWidth={maxWidth} wrapWidth={wrapWidth}>
            <Container padding={padding} margin={margin}  >
                <Spacing spacing={spacing}>
                    {children}
                </Spacing>
            </Container>
            {/*<Shadow/>*/}
        </Wrapper>

    )
};

Card.propTypes = {
    margin: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.string,
    children: PropTypes.node,
};

export default Card;

const Container = styled.div`
    z-index: 10;
    position: relative;
    box-shadow: 0 20px 45px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    margin: ${({margin}) => margin.def};

    ${media.laptop`
        margin: ${({margin}) => margin.laptop};
    `}

    ${media.phone`
        margin: ${({margin}) => margin.phone};
    `}

    ${props => props.padding};
`;

const Wrapper = styled.div`
    position: relative;
    width:  ${props => props.width};
    height:  ${props => props.height}px;
    flex-basis: ${({basis}) => basis ? '100%' : 'initial'};
    ${Container}{
        width:  ${props => props.width};
        height:  ${props => props.height}px;
    }

    ${media.desktop`
        max-width:  ${({maxWidth = '1258px'}) => maxWidth};
        width:  ${({wrapWidth = '100%'}) => wrapWidth};
    `}
`;

const Shadow = styled.div`
    left: -22px;
    bottom: -6px;
    z-index: 0;
    position: absolute;
    width: 22px;
    height: 197.8px;
    opacity: 0.32;
    -webkit-filter: blur(4.5px);
    filter: blur(4.5px);
    background-color: #000000;
`;

const Spacing = styled.div`
    height: 100%;
    ${props => props.spacing};
`;

