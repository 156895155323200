import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

class Carousel extends React.Component {
    static propTypes = {
        current: propTypes.number,
        onChildrenCount: propTypes.func,
        onChange: propTypes.func,
        autoplay: propTypes.bool,
        interval: propTypes.number
    };

    static defaultProps = {
        current: 0,
        onChildrenCount: () => {
        },
        onChange: () => {
        },
        autoplay: false,
        interval: 5000,
    };

    wrapperRef = React.createRef();

    state = {
        clientWidth: 0,
        current: 0,
        count: 0,
        timer: null
    };

    static getDerivedStateFromProps({current = 0, children}) {
        return {
            count: React.Children.count(children),
            current
        }
    }

    autoPlay = (interval) => {
        const timer = setInterval(() => {
            const {current, count} = this.state;
            const next = current + 1 < count ? current + 1 : 0;
            this.props.onChange(next);
        }, interval);

        this.setState({
            timer
        })
    };

    setClientWidth = () => {

        const {current} = this.wrapperRef || {};
        if (!current) {
            return;
        }

        const {clientWidth} = current;
        if (clientWidth !== this.state.clientWidth) {
            this.setState({
                clientWidth
            });
        }
    };

    componentDidUpdate() {
        this.setClientWidth();
    }

    componentDidMount() {
        this.setClientWidth();

        const {children, onChildrenCount, autoplay, interval} = this.props;

        onChildrenCount(React.Children.count(children));

        if (autoplay) {
            this.autoPlay(interval)
        }
    }

    componentWillUnmount() {
        const {timer} = this.state;
        if (timer) {
            clearInterval(timer)
        }
    }

    render() {
        const {children} = this.props;
        const {clientWidth, current, count} = this.state;
        return (
            <Wrapper ref={this.wrapperRef}>
                <Container current={current} width={clientWidth} count={count}>
                    {React.Children.map(children, (item) => <Item> {item} </Item>)}
                </Container>
            </Wrapper>
        )
    }
}

export default Carousel;

const Wrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

const Item = styled.div``;

const Container = styled.div`
    width: ${({count, width}) => count * width }px;
    display: flex;
    transform: translateX(${({current, width}) => (current * width * -1)}px);
    transition: transform 0.5s ease-in-out;
    ${Item}{
        width: ${props => props.width}px;
    }
`;





