export default {
    primaryColor: '#fff',
    primaryColorTint1: '#2b69f7',
    hover: '#fff',
    color: '#383838',
    secondaryColor: '#f2f8f8',
    fontFamily: 'Encode Sans Semi Condensed',
    sizes: {
        desktop: 1280,
        laptop: 1024,
        tablet: 767,
        phone: 414,
        mPhone: 375,
    },
};
