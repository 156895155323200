import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Highlight = ({children}) => {
    return <Wrapper>{children}</Wrapper>
};

Highlight.propTypes = {
    children: PropTypes.node
};

export default Highlight;

const Wrapper = styled.span`
  background-color: ${ ({ theme }) => theme.primaryColor };
  color:  ${ ({ theme }) => theme.color };;
  font-weight: 400;
  padding: 2px 9px 2px 2px;
`;