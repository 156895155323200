import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Space = ({ height = 0, width = 1 }) => {
    return  <Container height={height} width={width}/>
};

Space.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

export default Space;

const Container = styled.div`
    height: ${props => props.height}px;
    width: ${props => props.width}px;
`;