import React from "react";
import styled from "styled-components";
import {Link as GatsbyLink} from "gatsby";

// Images
import logo from '../images/AgePassLogo@2x.png';

const Logo = ({ height = '54.1px', width = '160.5px' }) => {
    return (
        <Link to="/">
            <Image height={height} width={width} src={logo} alt="logo"/>
        </Link>
    )
};

export default Logo;

const Image = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
`;

const Link = styled(GatsbyLink)`
    display: flex;
    text-align: right;
`;