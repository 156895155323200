import React from 'react';
import styled from 'styled-components';

const Line = styled.span`
    width: ${props => props.width};
    height: ${props => props.height};
    background: ${props => props.theme.primaryColor};
    display: inline-block;
    opacity: 0.3;
`;

export default Line;