import React from 'react';
import styled, {withTheme} from 'styled-components';
import useMedia from 'react-use/lib/useMedia';

// Styles
import {Flex} from "../styles";

const Quote = ({ children, theme }) => {
    const isPhone = useMedia(`(max-width: ${theme.sizes.phone}px)`);
    return (
        <Wrapper>
            <Symbol position={isPhone ? '30px' : '60px'}>
                “
            </Symbol>
            <Content>
                { children }
            </Content>
            <Symbol position={isPhone ? '-30px' : '-60px'}>
                <Flex justifyContent="flex-end">
                    „
                </Flex>
            </Symbol>
        </Wrapper>
    )
};

export default withTheme(Quote);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Symbol = styled.div`
    font-family: ${({theme}) => theme.fontFamily};
    color: ${({theme}) => theme.primaryColor};
    font-size: 100px;
    font-weight: 800;
    display: flex;
    z-index: 5;
    transform: translateY(${props => props.position});
    @media (max-width: ${props => props.theme.sizes.phone}px) {
          font-size: 80px;
    }
`;

const Content = styled.div`
    margin: 0 80px;
    font-size: 18px;
    font-weight: 800;
    color: ${({theme}) => theme.primaryColor};
    z-index: 50;
    @media (max-width: ${props => props.theme.sizes.phone}px) {
          margin: 0 0 0 0;
          font-size: 14px;
    }
`;
