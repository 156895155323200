import React from 'react';
import styled from "styled-components";
import { media } from '../theme';


const ListIcon = ({ icons = [], padding }) => {
    return (
        <IconsContainer padding={padding}>
            { icons.map( ([ title, icon ]) => {
                return (
                    <Item number={icons.length} key={title}>
                        <Img src={icon} alt={title}/>
                        <Label>{title}</Label>
                    </Item>
                )
            }) }
        </IconsContainer>
    )
};

export default ListIcon;

const IconsContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 1.38;
    ${({padding}) => padding};

    ${media.laptop`
        flex-direction: row;
    `}

    ${media.phone`
        flex-direction: row;    
    `}
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    height: 87px;
    align-items: center;
    justify-content: space-between;
    width: calc(100% / ${props => props.number});
    ${media.laptop`
        width: 33%;
        height: 75px;
        justify-content: initial;
        margin: 25px 0;
    `}

    ${media.phone`
        width: 33%;
        height: 66px;
        justify-content: initial;
        margin: 5px 0;
    `}
`;

const Img = styled.img`  
    ${media.laptop`
        height: 2.8rem;
    `}

    ${media.phone`
        height: 2.2rem;
    `}
`;

const Label = styled.span`
    font-family: ${({theme}) => theme.fontFamily};
    text-align: center;
    color: ${({theme}) => theme.primaryColor};
    max-width: 100px;
    height: 1rem;

    ${media.laptop`
        font-size: 13px;
        line-height: 1.46;
        margin-top: 1rem;
    `}

    ${media.phone`
        font-size: 13px;
        line-height: 1.46;
    `}
`;